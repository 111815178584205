import * as React from "react";
import {connect} from "react-redux";
import loadable from "@loadable/component";

const FormContent = loadable(({fileName}) => import(`./forms/${fileName}.js`), {
    cacheKey: ({fileName}) => fileName
});

const loadFile = file => import(`../validators/${file}Validator.js`);

const FormComponent = ({currentCodeData, gameType, gameData, currentRound, player, availablePlayers, dispatch}) => {
    const [fileName, setFileName] = React.useState(null);

    React.useEffect(() => {
        setFileName(currentCodeData.formType + "FormComponent");
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = (choice) => {
        const validatorFile = loadFile(currentCodeData.validator);
        validatorFile.then(({validate}) => {
            validate({choice, gameType, gameData, currentCode: currentCodeData.code, currentRound, availablePlayers, dispatch});
        });
    };

    return (
        <>
            {currentCodeData != null && fileName && (<FormContent fileName={fileName} formData={currentCodeData.formData} currentCode={currentCodeData.code} player={player} submitTrigger={handleSubmit}/>)}
        </>
    );
};

export default connect(state => ({
    gameType: state.global.type,
    gameData: state[state.global.type],
    currentRound: state.global.currentRound,
    currentCodeData: state.global.currentCode,
    player: state[state.global.type].player != null && state[state.global.type].player,
    availablePlayers: state.global.availablePlayers
}), null)(FormComponent);
