import * as React from "react";
import {connect} from "react-redux";

// components
import DefaultLayoutComponent from "../../components/layouts/DefaultLayoutComponent";
import FormComponent from "../../components/FormComponent";
import {navigate} from "gatsby";

const DecisionChoixPage = ({isNewState, gameType, codeData, currentRound, totalRounds}) => {
    React.useEffect(() => {
        if (isNewState) {
            navigate('/');
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    let wrapperClasses = "h-100 d-flex flex-column justify-content-between p-3";

    if (gameType === "main" || (codeData != null && codeData.code === "PROFIL")) {
        wrapperClasses += " bg-dark";
    }

    return (
        <DefaultLayoutComponent currentRound={currentRound} totalRounds={totalRounds}>
            {!isNewState && (
                <div className={wrapperClasses}>
                    {gameType === "main" &&
                     <div className="title-wrapper text-center">
                         <h1 className="display-6 stone-dark mb-0">RENCONTRE</h1>
                         {codeData != null && codeData.hasIntroduction &&
                          <h4 className="text-muted">{codeData.introData.encouteredCharacter}</h4>
                         }
                     </div>
                    }
                    <FormComponent/>
                </div>
            )}
        </DefaultLayoutComponent>
    )
};

export default connect(state => ({
    isNewState: state.global.codeList == null,
    gameType: state.global.type,
    currentRound: state.global.currentRound,
    totalRounds: state[state.global.type].totalRounds,
    codeData: state.global.currentCode
}), null)(DecisionChoixPage)
